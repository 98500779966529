<template lang="pug">
    #unsupported-browser
      .modal-header
        h2 Unsupported Browser

      .modal-text
        icon#warning-icon(data="@icon/warning.svg")
        p Please open this app in {{ browserName }} to continue

        button.copy-link(@click="copyURLToClipboard") Copy Link

  </template>

<script>
export default {
  props: {},

  data() {
    return {
      isSafari: false,
    }
  },

  created() {
    this.isSafari = /iphone/i.test(navigator.platform)
  },

  computed: {
    browserName,
  },

  watch: {},

  methods: {
    copyURLToClipboard,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function browserName() {
  return this.isSafari ? 'Safari' : 'Chrome'
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function copyURLToClipboard() {
  navigator.clipboard.writeText(document.URL)
}
</script>
